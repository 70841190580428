import React from 'react';
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from '@mui/material';
import CaratLaneLogoFull from'./assets/logo/CaratLaneLogoFull.png';
import { ref, set, push } from 'firebase/database';
import { projectFirebase } from './firebase';
import './TVComp.css';

function LandingScreen() {
  const navigate = useNavigate();
  const startCapture = () => {
    console.log('startCapture::');
    navigate(`/img`);

    set(ref(projectFirebase, 'config/4/'), {
        startCapture : true
    });
  }

  useEffect(() => {
    console.log('LandingScreen useEffect::: Resetting startCapture');
    set(ref(projectFirebase, 'config/4/'), {
      startCapture : false
    });
  }, []);


  return (
   <div style={{ display:'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', height:'90vh'}}>
        <div style={{ display:'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height:'85%'}}>
            <p style={{ color:'#fff', fontSize: '20px', fontWeight:'bold'}}>Click, swipe & get featured!</p>
            <Button variant="contained" size="large" onClick={startCapture}
                style={{ color: "#fff", backgroundColor: "#953092"}}>
                Start Clicking
            </Button>
        </div>
        <img style={{ width:'50%'}}  src={CaratLaneLogoFull}/>
    </div>
  );
}
export default LandingScreen;