import React from 'react';
import { useState, useRef, useCallback, useEffect } from "react";
import 'animate.css';
import './TVComp.css';
import Webcam from 'react-webcam';
import { Button, TextField } from '@mui/material';
import TinderCard from 'react-tinder-card'
import { projectFirebase } from './firebase';
import { ref, set, push } from 'firebase/database';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Rating from '@mui/material/Rating';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import MessageIcon from '@mui/icons-material/Message';
import { useNavigate } from "react-router-dom";
import CaratLaneLogoFull from'./assets/logo/CaratLaneLogoFull.png';
import SwitchCamera from './assets/emoji/SwitchCamera.png';
import Capture from './assets/emoji/Capture.png';

const FACING_MODE_USER = "user";
const FACING_MODE_ENVIRONMENT = "environment";

const videoConstraints = {
  facingMode: FACING_MODE_USER
};

// const videoConstraints = {
  // width: { max: 480 },
  // height: { max: 720 },
  // aspectRatio: 0.6666666667,
//   facingMode: "environment"
// };

const StyledRating = styled(Rating)({
  '& .MuiRating-iconFilled': {
    color: '#953092',
  },
  '& .MuiRating-iconHover': {
    color: '#953092',
  },
});

const ImgComp = () => {
  const navigate = useNavigate();
  const webcamRef = useRef(null);
  const msgRef  = useRef();
  const ratingRef = useRef();
  const [imgSrc, setImgSrc] = useState(null);
  const [msg, setMsg] = useState("");
  const [msgTemp, setMsgTemp] = useState("");
  const [postId, setPostId] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [isPreview, setIsPreview] = useState(false);
  const [isRating, setIsRating] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [ratingVal, setRatingVal] = React.useState(0);
  const [ratingOpen, setRatingOpen] = React.useState(false);
  const [saveDisabled, setSaveDisabled] = React.useState(true);
  const [facingMode, setFacingMode] = React.useState(FACING_MODE_USER);

  const initialState = () => {
    console.log('initialState::');
    setIsPreview(false);
    setIsRating(false);
    setRatingOpen(false);
    setRatingVal(0);
  };

  const resetImgData = () => {
    console.log('Resetting Image Data::');
    setImgSrc(null);
    setMsg("");
    setMsgTemp("");
    setMobileNo("");
  };

  const switchCamera = useCallback(() => {
    setFacingMode(
      prevState =>
        prevState === FACING_MODE_USER
          ? FACING_MODE_ENVIRONMENT
          : FACING_MODE_USER
    );
  }, []);

  const capture = useCallback(() => {
      setImgSrc(webcamRef.current.getScreenshot());
      setIsPreview(true);
      handleClickOpen();
    },
    [webcamRef]
  );

  const submitRating = () => {
    console.log('submitRating::' + ratingVal);
    if(ratingVal){
      console.log('Updating DB - Rating::');
      set(ref(projectFirebase, 'store/4/'+postId+'/feedback/'), {
        rating: ratingVal
      });
    }
    initialState();
    navigate(`/`);
  };


  const outOfFrame = (imgSrc, msg) => {
    console.log('Saving new image:::');

    set(ref(projectFirebase, 'config/4/'), {
      startCapture : false
    });
    
    if(imgSrc && msg){
      let currentTs = new Date();
      const newPostRef = push(ref(projectFirebase, 'store/4'), {
        imgData: imgSrc,
        msg: msg,
        mobileNo: mobileNo,
        createts: currentTs.getTime(),
        createtsDesc: currentTs.toLocaleString()
        // createtsDesc1: currentTs.toString()
      });
      setPostId(newPostRef.key);

      set(ref(projectFirebase, 'latest/4/'), {
        imgKey: newPostRef.key,
        imgData: imgSrc,
        msg: msg,
        mobileNo: mobileNo,
        latest: true,
      });
      resetImgData();
    }
    setIsRating(true);
    setRatingOpen(true);
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    // setOpen(false);
  };

  const handleRatingClose = () => {
    setRatingOpen(false);
  };

  const handleSaveMsg = () => {
    setOpen(false);
    setMsg(msgRef.current.value);
  };

  const handleNumberChange = (e) => {
    const onlyNums = e.target.value.replace(/[^0-9]/g, '');
    if (onlyNums.length <= 10) {
      setMobileNo(onlyNums);
    }

    // if(onlyNums.length === 10 && msgTemp.length > 0){
    //   setSaveDisabled(false);
    // }else{
    //   setSaveDisabled(true);
    // }
  };

  const handleMsgChange = (e) => {
    const msgVal = e.target.value;
    if (msgVal.length <= 100) {
      setMsgTemp(msgVal);
    }

    // if(mobileNo.length === 10 && msgVal.length > 0){
    //   setSaveDisabled(false);
    // }else{
    //   setSaveDisabled(true);
    // }

    if(msgVal.length > 0){
      setSaveDisabled(false);
    }else{
      setSaveDisabled(true);
    }
  };

  return (
    <div>
      {isPreview ? 
        <>
          <TinderCard className='swipe'
            onCardLeftScreen={() => outOfFrame(imgSrc, msg)}
            preventSwipe={['left','right','down']}>
            <Card sx={{ minWidth:400 }} class="PreviewCard">
              <a href="/"><img style={{ width:'50%'}}  src={CaratLaneLogoFull}/></a>
              <CardMedia
                sx={{ height: 600 }}
                image={imgSrc}
              />
              <CardContent>
                <Typography variant="h5" gutterBottom color="text.secondary">
                  {msg}
                </Typography>
              </CardContent>
            </Card>
          </TinderCard>

          <Dialog open={open} onClose={handleClose}>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Enter Your Mobile No"
                type="text"
                fullWidth
                // required
                variant="standard"
                onChange={handleNumberChange}
                value={mobileNo}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <ContactPhoneIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                margin="dense"
                id="name"
                label="Enter Your Message Here"
                type="email"
                fullWidth
                required
                variant="standard"
                onChange={handleMsgChange}
                value={msgTemp}
                inputRef={msgRef}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MessageIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </DialogContent>
            <DialogActions>
              {/* <Button onClick={handleClose}>Cancel</Button> */}
              <Button onClick={handleSaveMsg} disabled={saveDisabled}>Save</Button>
            </DialogActions>
          </Dialog>
        </>
        :
        <div style={{ display:'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
          <a href="/"><img style={{ width:'50%'}}  src={CaratLaneLogoFull}/></a>
          
          <Webcam
            // mirrored={true} 
            // videoConstraints={videoConstraints}
            videoConstraints={{
              ...videoConstraints,
              facingMode
            }}
            width={400}
            height={600}
            audio={false}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            screenshotQuality="0.7"
          />
          <div style={{ width : '100%', display:'flex', flexDirection: 'row', alignItems: 'center'}}>
            <div class='flex1'>
              <img  style={{ width : '60%' }} src={SwitchCamera} onClick={switchCamera}/>
            </div>
            <div class='flex2'>
              <img  style={{ width : '20%' }} src={Capture} onClick={capture}/>
            </div> 
            <div class='flex3'>
            </div>
          </div>
        </div>
      }

      {isRating ? 
        <div style={{ display:'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
          <img style={{ width:'50%'}}  src={CaratLaneLogoFull}/>

          <Dialog open={ratingOpen} onClose={handleRatingClose}>
            <DialogContent>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center'
                }}
              >
                <Typography align="center" variant="h6" gutterBottom color="text.secondary">
                    Please rate your store experience
                </Typography>
                <StyledRating
                  name="customized-color"
                  defaultValue={0}
                  precision={1}
                  icon={<StarIcon fontSize="large" />}
                  emptyIcon={<StarBorderIcon fontSize="large" />}
                  ref={ratingRef}
                  onChange={(event, newValue) => {
                    setRatingVal(newValue);
                  }}
                />
              </Box>
            </DialogContent>
            <Button variant="contained" size="large" onClick={submitRating}>
              Submit Rating
            </Button>
          </Dialog>
        </div>
       :
        <></>
      }
    </div>
  );
}

export default ImgComp;