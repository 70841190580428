import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

const firebaseConfig = {
  apiKey: "AIzaSyAmUDa2PndZJmvvrasIQxaAmLnWLqJ39zE",
  authDomain: "caratlanepoc.firebaseapp.com",
  projectId: "caratlanepoc",
  storageBucket: "caratlanepoc.appspot.com",
  messagingSenderId: "565983847985",
  appId: "1:565983847985:web:0e730add52132f57595710"
};

const app = initializeApp(firebaseConfig);
const projectFirebase = getDatabase(app);
// const timestamp = firebase.firestore.FieldValue.serverTimestamp;

export { projectFirebase };