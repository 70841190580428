import { useState, useEffect } from 'react';
import { projectFirebase } from './../firebase';
import { onValue, ref, query, limitToFirst } from 'firebase/database';

const useFirebase = (collection) => {
  const [docs, setDocs] = useState([]);

  useEffect(() => {
    const dbFirebase = query(ref(projectFirebase, collection), limitToFirst(100));
    onValue(dbFirebase, (snapshot) => {
      let documents = [];
      console.log('useFirebase - Data Refreshed from DB ::::');
      snapshot.forEach(doc => {
        documents.push({...doc.val(), key: doc.key});
      });
      setDocs(documents);
    });

    return () => dbFirebase();
  }, [collection]);
  return { docs };
}

export default useFirebase;