import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import TVComp from './TVComp';
import TVPortraitComp from './TVPortraitComp';
import ImgComp from './ImgComp';
import DownloadComp from './DownloadComp';
import Test from './Test';
import LandingScreen from './LandingScreen';

const styles = {
  border: '0.0625rem solid #9c9c9c',
  borderRadius: '0.25rem',
};

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<LandingScreen/>} />
        <Route path='/img' element={<ImgComp/>} />
        <Route path='/tv' element={<TVComp/>} />
        <Route path='/tv/portrait' element={<TVPortraitComp/>} />
        <Route path='/download/:key' element={<DownloadComp/>} />
        {/* <Route path='/test' element={<Test/>} /> */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
