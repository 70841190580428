import React from 'react';
import { useParams } from 'react-router';
import { useState, useEffect, useRef } from "react";
import { projectFirebase } from './firebase';
import { onValue, ref } from 'firebase/database';
import './TVComp.css';

import FaceHeart from'./assets/emoji/FaceHeart.png';
import PurpleHeart from'./assets/emoji/PurpleHeart.png';
import Hug from'./assets/emoji/Hug.png';
import Stars from'./assets/emoji/Stars.png';
import Sparkle from './assets/emoji/Sparkle.png';
import MyCaratLaneStory from './assets/emoji/MyCaratLaneStory.png';
import GiftACaratLane from './assets/emoji/GiftACaratLane.png';
import html2canvas from 'html2canvas';
import downloadjs from 'downloadjs';

const DownloadComp = () => {

  const { key } = useParams();
  const [isPreview, setIsPreview] = useState(false);
  const [imgData, setImgData] = useState("");
  const [msg, setMsg] = useState("");
  const divRef  = useRef();

  
  const date = new Date();
  const showTime = date.getHours().toString() + date.getMinutes().toString() + date.getSeconds().toString();
  let fileName = "Caratlane" + showTime;
  console.log('fileName:'+ fileName);

  useEffect(() => {
    const query = ref(projectFirebase, 'store/4/'+key);
    onValue(query, (snapshot) => {
      if (snapshot.exists()) {
        setIsPreview(true);
        setImgData(snapshot.val().imgData);
        setMsg(snapshot.val().msg);
      }
    });
  }, []);
  
  const downloadImg = async() => {
    const canvas = await html2canvas(divRef.current);
    const dataURL = canvas.toDataURL('image/jpeg');
    downloadjs(dataURL, fileName + '.jpeg', 'image/jpeg');

    document.getElementById('tvDiv').classList.remove('container');
    document.getElementById('tvDiv').classList.add('downloadContainer');
  };

  return (
    <div>
      {isPreview ? 
        <div id="tvDiv" class="container" ref={divRef}>
          <div style={{ height:'96%'}} class="image-container">
            <img style={{ height:'100%'}} src={imgData} onLoad={downloadImg}></img>
            <div class="title">
              <span>{msg}</span>
            </div>

            <img class="faceEmoji1" src={FaceHeart}/>
            <img class="faceEmoji2" src={Hug}/>
            <img class="starEmoji1" src={Sparkle}/>
            <img class="starEmoji2" src={Sparkle}/>
            <img class="heartEmoji1" src={PurpleHeart}/>
            <img class="heartEmoji2" src={PurpleHeart}/>
            <img class="heartEmoji3" src={PurpleHeart}/>
            <img class="story" src={MyCaratLaneStory}/>
            <img class="gift" src={GiftACaratLane}/>
          </div>          
        </div>
        :
        <></>
      }
    </div>
  );
}

export default DownloadComp;