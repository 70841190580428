import React from 'react';
import { useState, useEffect, useRef } from "react";
import useFirebase from './hooks/useFirebase';
import { projectFirebase } from './firebase';
import { onValue, ref, set } from 'firebase/database';
import 'animate.css';
import "./styles/instagram.css";
import './TVComp.css';
import './ImageEffect.css';

import FaceHeart from'./assets/emoji/FaceHeart.png';
import PurpleHeart from'./assets/emoji/PurpleHeart.png';
import Hug from'./assets/emoji/Hug.png';

import Sparkle from './assets/emoji/Sparkle.png';
import MyCaratLaneStory from './assets/emoji/MyCaratLaneStory.png';
import GiftACaratLane from './assets/emoji/GiftACaratLane.png';
import {QRCodeCanvas} from 'qrcode.react';
import LoadingGif2 from './assets/emoji/LoadingGif2.gif';
import SparkleFrame1 from './assets/emoji/SparkleFrame1.gif';
import SparkleFrame2 from './assets/emoji/SparkleFrame2.gif';

const TVComp = () => {

  const { docs } = useFirebase('store/4');

  const divRef  = useRef();
  const [isPortrait, setIsPortrait] = useState(false);
  const [imgData, setImgData] = useState("");
  const [isLatest, setIsLatest] = useState(false);
  const [isCapturing, setIsCapturing] = useState(false);
  const [isPreview, setIsPreview] = useState(false);
  const [isDefaultImg, setIsDefaultImg] = useState(false);
  const [msg, setMsg] = useState("");
  const [imgKey, setImgKey] = useState("");
  let [currIndex, setCurrIndex] = useState(0);
  let [iterationCount, setIterationCount] = useState(0);

  let date = new Date();  

  useEffect(() => {
    const mediaQueryPortrait = window.matchMedia('(orientation: portrait)');
    const mediaQueryLandscape = window.matchMedia('(orientation: landscape)');

    if (mediaQueryPortrait.matches) {
      console.log('Inside portrait:');
      setIsPortrait(true);
    } else if (mediaQueryLandscape.matches) {
      console.log('Inside landscape:');
      setIsPortrait(false);
    }

    const query = ref(projectFirebase, 'config/4/');
    onValue(query, (snapshot) => {
      if (snapshot.exists()) {
        setIsCapturing(snapshot.val().startCapture);
        if(snapshot.val().startCapture){
          setIsDefaultImg(false);
        }
      }
    });

    const imgQuery = ref(projectFirebase, 'latest/4/');
    onValue(imgQuery, (snapshot) => {
      if (snapshot.exists()) {
        if(snapshot.val().latest){
          setIsPreview(true);
          setIsLatest(true);
          setIsDefaultImg(false);
          
          setMsg(snapshot.val().msg);
          setImgKey(snapshot.val().imgKey);
          setImgData(snapshot.val().imgData);
        } else{
          setCurrIndex(0);
        }
      }
    });
  }, []);

  function resetLatestImg(){
    if(isLatest){
      console.log('Adding Extra Delay - 15sec:::');
      wait(15000);
      date = new Date();
      console.log('time:'+ date.getHours()+ '-' + date.getMinutes()+ '-' + date.getSeconds());
  
      set(ref(projectFirebase, 'latest/4/'), {
        latest: false
      });
      setIsLatest(false);
    }
  }

  function wait(ms){
      var start = new Date().getTime();
      var end = start;
      while(end < start + ms) {
        end = new Date().getTime();
    }
  }

  function loadImage(index){
    if(docs[currIndex]){
      console.log('Inside loadImage:');
      setIsPreview(true);
      setIsDefaultImg(false);
      setMsg(docs[index].msg);
      setImgKey(docs[index].key);
      setImgData(docs[index].imgData);
    }
  }

  const loadDefaultImg = () => {
    return new Promise((resolve) => {
      setTimeout(() => {
        console.log('Inside loadDefaultImg = Completed 2 Slides:::');
        setIsDefaultImg(true);
        resolve();
      }, 3000);
    });
  };


  const initMethod = async () => {
    if(docs[currIndex]){
      console.log('initMethod: '+currIndex);
      loadImage(currIndex);

      // Check if it's the second occurrence (every 6 seconds) and invoke the loadDefaultImg
      if (currIndex % 2 === 1) {
        await loadDefaultImg();
      }

      // Update the current index to loop back to the beginning when the end is reached
      setCurrIndex((prevIndex) => (prevIndex + 1) % docs.length);
      // Increment the iteration count to trigger the effect on every iteration
      setIterationCount((prevCount) => prevCount + 1);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(initMethod, 6000);
    return () => clearInterval(intervalId);
  }, [docs, iterationCount]);

  return (
    <div className='bg'>
      {(isPreview && !isCapturing && !isDefaultImg )? 
        <div id="tvDiv" className={isPortrait ? 'containerPortrait' : 'container'} ref={divRef}>
          <div style={{ height:'96%', top:'3px'}} className="image-container">
            <img style={{ height:'100%'}} src={imgData} onLoad={resetLatestImg}></img>

            <div className="title">
              <span>{msg}</span>
            </div>

            <img className={isPortrait ? 'faceEmoji1Portrait' : 'faceEmoji1'} src={FaceHeart}/>
            <img className={isPortrait ? 'faceEmoji2Portrait' : 'faceEmoji2'} src={Hug}/>
            <img className="starEmoji1" src={Sparkle}/>
            <img className="starEmoji2" src={Sparkle}/>

            <img className="sparkleFrame1" src={SparkleFrame1}/>
            <img className="sparkleFrame2" src={SparkleFrame2}/>

            <img className={isPortrait ? 'heartEmoji1Portrait' : 'heartEmoji1'} src={PurpleHeart}/>
            <img className={isPortrait ? 'heartEmoji2Portrait' : 'heartEmoji2'} src={PurpleHeart}/>
            <img className={isPortrait ? 'heartEmoji3Portrait' : 'heartEmoji3'} src={PurpleHeart}/>
            <img className="story" src={MyCaratLaneStory}/>
            <img className={isPortrait ? 'giftPortrait' : 'gift'} src={GiftACaratLane}/>
          </div>          
        </div>
        :
        <></>
      }
      {(isPreview && !isCapturing && !isDefaultImg ) && 
        <span className="qr">
          {isPortrait && 
            <QRCodeCanvas 
              style={{ width:'5vw', height:'5vw'}} 
              value={"https://swipe.coitor.com/download/" + imgKey} />
          }
          {!isPortrait && 
            <QRCodeCanvas 
              style={{ width:'7vw', height:'7vw'}}
              value={"https://swipe.coitor.com/download/" + imgKey} />
          }
        </span>
      } 
      {isCapturing &&
        <div style={{ display:'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height:'90vh'}}>
          <img style={{ width:'72%'}} src={LoadingGif2}/>
        </div>
      }
      {isDefaultImg &&
        <div style={{ display:'flex', flexDirection: 'column', justifyContent: 'center', width:'70vh', height:'90vh'}}>
          <div className="defaultMsg">
            <div>To leave a review,</div>
            <div>PLEASE SWIPE UP</div>
            <div>Contact store for details.</div>
          </div>
        </div>
      }
    </div>
  );
}

export default TVComp;